/* Beispielhafter Override für die antd Collapse-Komponenten im Dark Mode. */
/* 'faq-collapse' ist die className, die wir in <Collapse> vergeben haben. */

.dark .faq-collapse .ant-collapse-header {
  background-color: #1e293b !important; /* slate-800 */
  color: #fff !important;
}

.dark .faq-collapse .ant-collapse-item {
  border-color: transparent !important; /* z.B. slate-700 für den Rahmen */
  border-radius: 0px !important;
}

.dark .faq-collapse .ant-collapse-content {
  background-color: #1e293b !important; /* slate-800 */
  color: #fff !important;
}

.dark .faq-collapse .ant-collapse-content-box {
  color: #ffffff !important;
}

/* Entferne alle Standard-Rahmen */
.faq-collapse .ant-collapse-item {
  border: none !important;
}

.faq-collapse .ant-collapse {
  background-color: #E0E9F1 !important;
}

/* Füge unter jedem Item einen Divider hinzu, außer beim letzten */
.faq-collapse .ant-collapse-item:not(:last-child) {
  border-bottom: 1px solid #d9d9d9 !important;
}
.dark .faq-collapse .ant-collapse-item:not(:last-child) {
  border-bottom: 1px solid #374151 !important; /* Beispiel: dunklerer Ton */
}
