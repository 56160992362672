/* Wrapper: Begrenzung der sichtbaren Fläche auf 100vw */
.logos-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 60px 20px;
  background: transparent;
}

.logos-wrapper:before,
.logos-wrapper:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos-wrapper::before {
  left: 0;
  background: var(
    --gradient-bg-left,
    linear-gradient(to left, rgba(255, 255, 255, 0), white)
  );
}

.logos-wrapper::after {
  right: 0;
  background: var(
    --gradient-bg-right,
    linear-gradient(to right, rgba(255, 255, 255, 0), white)
  );
}

/* Logos-Container: Enthält beide Slides, 140vw breit, mit Animation */
.logos {
  display: flex;
  width: 140vw;
  animation: slide 20s linear infinite;
  position: relative;
}

/* Jede Slide: Desktop-Ansicht (70vw) */
.logos-slide {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70vw;
}

/* Icons: Festgelegte Höhe (Desktop) */
.icon {
  height: 64px;
}

/* Animation: Verschiebe den gesamten Container von 0 bis -70vw */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-70vw);
  }
}

/* Media Query für Geräte mit maximal 768px Breite */
@media (max-width: 768px) {
  .logos-wrapper:before,
  .logos-wrapper:after {
    width: 64px; /* Statt 250px jetzt kleiner für mobile */
  }
  .logos {
    width: 200vw;
    animation: slide 20s linear infinite;
  }
  .logos-slide {
    width: 100vw;
  }
  .icon {
    height: 64px;
  }
  /* Überschreibt die Animation für mobile Geräte */
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }
}

/* Media Query für Geräte mit maximal 480px Breite */
@media (max-width: 480px) {
  .logos-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 30px 20px;
    background: transparent;
  }
  .logos-wrapper:before,
  .logos-wrapper:after {
    width: 50px; /* Noch kleiner für sehr kleine Screens */
  }
  .logos {
    width: 400vw;
    animation: slide 20s linear infinite;
  }
  .logos-slide {
    width: 200vw;
  }
  .icon {
    height: 48px;
  }
  /* Überschreibt die Animation für sehr kleine Geräte */
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200vw);
    }
  }
}

